<template>
  <div class="container">
    <div class="banner">
      <div class="tip">
        <img src="../../assets/laba.png">
        <p class="message">{{msg}}</p>
      </div>
    </div>
    <div class="bm_form">
      <p class="title">考生报名</p>
      <form>
        <div class="item clearAfter">
          <span>姓名</span>
          <Input type="text" v-model="formInline.name" name="name" placeholder="请填写真实姓名" maxlength="20"/>
        </div>
        <div class="item clearAfter">
          <span>电话</span>
          <Input type="text" v-model="formInline.phone" name="phone" placeholder="请填写有效联系方式" maxlength="11"/>
        </div>
        <div class="check">
          <Checkbox size="small" v-model="isAgree"> 同意用户《注册协议》，并授权使用个人信息 </Checkbox>
        </div>
        <Button class="btn-box" @click="handleSubmit">提 交</Button>
      </form>
    </div>
    <div class="bm_prompt">
      <h5>*温馨提示</h5>
      <ul>
        <li>
          <p class="num">1、</p>
          <span>注册时请务必输入正确的姓名和手机号码，以免影响考后成绩查询</span>
          </li>
        <li>
          <p class="num">2、</p>
          <span> 基金报名相关信息：<br>
          考试时间（预约考）：2020年10月31日<br>
          考试报名时间：2020年9月14日至10月9日<br>
          考试时间（统考）：2020年11月28日<br>
          考试报名时间：2020年10月19日至11月2日</span>
        </li>
        <li>
          <p class="num">3、</p>
          <span>准考证打印时间：考前一周</span>
        </li>
        <li>
          <p class="num">4、</p>
          <span>最终解释权归乐考学堂所有</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { getData } from '@/api/signUp'

export default {
  name: 'mobile',
  data () {
    return {
      formInline: {
        name: '',
        phone: ''
      },
      msg: '您提交信息后将会有专业老师电话指导您进行考试报名，并提供相关报名服务、优惠信息，根据您的需求提供相关考试培训服务，请您保持电话畅通',
      isAgree: true
    }
  },
  created () {
    this.d()
  },
  methods: {
    handleSubmit () {
      if (!this.formInline.name) {
        this.$layer.msg('考生姓名必填！')
        return false
      }
      if (!this.formInline.phone) {
        this.$layer.msg('考生电话必填！')
        return false
      }
      if (!(/^1\d{10}$/.test(this.formInline.phone))) {
        this.$layer.msg('考生电话格式不正确！')
        return false
      }
      if (!this.isAgree) {
        this.$layer.msg('请勾选同意注册协议！')
        return false
      }
      this.formInline.parse_url = decodeURI(this.$route.fullPath)
      this.formInline.source = 'baidu.mobile'
      this.formInline.url = window.location.href
      this.formInline.bd_vid = this.$route.query.bd_vid

      getData(this.formInline)
        .then(async res => {
          if (res.status === 1) {
            this.$layer.msg('报名成功！')
            window.location.href = 'https://www.lekaoschool.com'
          } else {
            this.$layer.msg('网络错误，请稍后再试！')
          }
        })
        .catch(error => {
          console.log(error)
          this.$layer.msg('网络错误，请稍后再试！')
        })
    },
    d () {
      setInterval(() => {
        const start = this.msg.substring(0, 1)
        const end = this.msg.substring(1)
        this.msg = end + start
      }, 600)
    }
  }
}
</script>

<style scoped>
.container {
  width: 100%;
  margin: 0 auto;
  background: #FFFFFF;
}
.banner {
  position: relative;
  width: 100%;
  height: 12rem;
  background: url('../../assets/banner.png') no-repeat center top;
  background-size: 100% 12rem;
}
.banner .tip {
  position: absolute;
  bottom: 0;
  height: 1.8rem;
  line-height: 2rem;
  padding: 0 0.5rem;
  background: url('../../assets/touming.png') no-repeat center top;
  background-size: auto 1.8rem;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.banner .tip img {
  display: inline-block;
  vertical-align: middle;
  width: 1rem;
  height: 0.8rem;
  margin: -0.2rem 0.5rem 0 0;
}
.message{
  flex: 1;
  height: 1.8rem;
  line-height: 1.8rem;
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.75);
}
.bm_form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2rem 0 0;
}
.bm_form .title {
  padding-top: 2.8rem;
  text-align: center;
  font-size: 1.2rem;
  color: #FA535A;
  background: url('../../assets/xia.png') no-repeat center top;
  background-size: auto 1.8rem;
}
.bm_form form {
  margin-top: 1rem;
}
.bm_form form .item {
  margin-bottom: 0.6rem;
  border-bottom: 0.04rem solid #dbd6d6;
  display: flex;
  align-items: center;
}
.bm_form .item span {
  width: 2rem;
  line-height: 3rem;
  font-size: 0.8rem;
}
/deep/ .ivu-input {
  display: block;
  padding-left: 1rem;
  height: 3rem;
  font-size: 0.8rem;
  color: #666;
  border: none;
  box-shadow: none;
}
.bm_form .check {
  margin-top: 1rem;
}
/deep/ .ivu-checkbox-wrapper{
  font-size: 0.2rem;
  color: #999;
}
.bm_form .btn-box {
  width: 100%;
  height: 2.4rem;
  margin-top: 1.6rem;
  font-size: 1rem;
  color: #FFF;
  background: #FA535A;
  border: none;
  border-radius: 1.2rem;
}
.bm_prompt {
  margin-top: 2rem;
  padding: 2rem 1rem;
  background: #FA535A;
  text-align: left;
}
.bm_prompt h5 {
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
  color: #FFF;
}
ul {
  list-style: none;
}
.bm_prompt ul li {
  position: relative;
  line-height: 1.8rem;
  font-size: 0.8rem;
  color: #FFF;
  display: flex;
}
</style>
